import { Component, ViewChild } from '@angular/core';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Landing-Blackfriday-GTM';

   imagesHotSale = [
    "/assets/img/black/3MSI.jpg",
    "/assets/img/black/COBERTORES.jpg",
    "/assets/img/black/FUNDAS.jpg",
    "/assets/img/black/GTM.jpg",
    "/assets/img/black/INVIERNO.jpg",
    "/assets/img/black/PUNTOS.jpg",
    "/assets/img/black/TEENS.jpg",
    "/assets/img/black/VIANNEY.jpg"
  ]

  public isAdi = '';
  public email = '';

  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;

  constructor(
    private http: HttpClient
    ){    
  }

  @ViewChild('carousel', {static : true}) carousel: NgbCarousel;

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }

  public guardar(){
    let arroba = this.email.includes('@');
    let punto = this.email.includes('.');  

    if(arroba == true && punto == true )
    {
      if(this.isAdi != null && this.isAdi != "")
      {
        // console.log(this.email)
        // console.log(this.isAdi)
        this.http.get("https://wsvianneymx-dot-mx-vianney-001.appspot.com/api/consultaemail_suscriptor/"+this.email+"/LANDING BLACKFRIDAY GTM 2020", {}).subscribe(response =>{
          // console.log("response")
          // console.log(JSON.parse(JSON.stringify(response)).length)
          if(JSON.parse(JSON.stringify(response)).length == 0)
          {
              this.http.post("https://wsvianneymx-dot-mx-vianney-001.appspot.com/api/suscriptorLandingPage", {
                correo : this.email,
                origen : "GTM",
                afiliado : this.isAdi,
                descripcion : "LANDING BLACKFRIDAY GTM 2020"
              }).subscribe(response => {
                  //console.log('Response is ', response);
                  Swal.fire(
                    '',
                    'Gracias por registrarse!',
                    'success'
                  ).then((result) => {
                    if (result.value) {
                        location.reload();
                    }
                  }) 
              }, err => {
                Swal.fire({
                  type: 'error',
                  text: 'Lo sentimos no se pudo registrar, intentelo de nuevo.'
                })
              });
          }
          else{
            Swal.fire({
              type: 'error',
              text: 'El correo ya existe!'
            })
          }
        })
      }
      else{
        Swal.fire({
          type: 'error',
          text: 'Seleccione una opcion!'
        })
      }     
    }
    else{
      Swal.fire({
        type: 'error',
        text: 'Ingrese un correo valido!'
      })
    }
  }
}
